import { useQuery } from "react-query";
import PropTypes from "prop-types";
import { directusClient } from "../../hooks/useDirectus";
import { cardFields } from "../cards/hooks";

function useSearchCards(filters) {
    return useQuery([`search`, filters], () => getCardResults(filters), {
        keepPreviousData: true,
        staleTime: 86400000,
    });
}

// TODO: Performance - Create a global storage hook that uses LocalStorage to reduce API queries
function getCardResults(filters) {
    const languageFilter = filters.l ? { language: { eq: filters.l } } : null;
    const filterByGame = filters.g
        ? { "set.series.game.ref": { eq: filters.g } }
        : null;
    const filterBySeries = filters.se
        ? { "set.series.id": { eq: filters.se } }
        : null;
    const filterBySet = filters.s ? { "set.id": { eq: filters.s } } : null;
    const convertedFilters = {
        ...languageFilter,
        ...(filterBySet || filterBySeries || filterByGame),
    };
    const defaultFilters = {
        name: {
            like: filters && filters.q,
        },
        status: {
            eq: `published`,
        },
        "set.status": {
            eq: `published`,
        },
        "set.series.status": {
            eq: `published`,
        },
        "set.series.game.status": {
            eq: `published`,
        },
    };

    return directusClient.get(`/items/card`, {
        meta: `filter_count,offset,page,page_count,links,result_count`,
        fields: cardFields,
        filter: { ...defaultFilters, ...convertedFilters },
        sort: `name`,
        page: filters.page || 1,
        limit: filters.limit || 25,
    });
}

getCardResults.propTypes = {
    filters: PropTypes.object.isRequired,
};
useSearchCards.propTypes = {
    searchString: PropTypes.string.isRequired,
    filters: PropTypes.object,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number,
};

export { useSearchCards };
