import * as React from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import { Link } from "gatsby";
import { CardRarity } from "./CardRarity";
import { CardImage } from "./CardImage";

export const CardGridListItem = ({ card, paperClasses }) => {
    return (
        <li>
            <Link
                className="block h-full hover:opacity-75"
                to={`/card/${card.id}/${card.url_title}`}
            >
                <Card
                    className={`flex flex-col flex-wrap h-full ${paperClasses}`}
                >
                    <CardImage
                        alt={card.name}
                        assetUrl={card.image && card.image.data.asset_url}
                        className="w-full"
                        fullUrl={card.image_url}
                        height={200}
                        width={143}
                    />
                    <CardContent>
                        <Typography
                            align="center"
                            component="h3"
                            variant="subtitle1"
                        >
                            {card.name} {card.subtitle ? card.subtitle : null}
                        </Typography>
                        <Typography
                            align="center"
                            color="textSecondary"
                            variant="subtitle2"
                        >
                            <CardRarity
                                rarityNum={card.rarity && card.rarity.id}
                            />
                            <br />
                            Set: {card.set.name}
                        </Typography>
                    </CardContent>
                </Card>
            </Link>
        </li>
    );
};
