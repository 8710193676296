import * as React from "react";
import { useQueryParams, StringParam, NumberParam } from "use-query-params";
import { Button, TextField } from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import { useTheme } from "@material-ui/core/styles";

/*
We're managing search filter state in the URL to provide browser history and shareable URLs
 */
export const SearchForm = () => {
    const theme = useTheme();
    const [query, setQuery] = useQueryParams({
        q: StringParam,
        page: NumberParam,
    });
    return (
        <form
            className={`flex`}
            onSubmit={(event) => {
                event.preventDefault();
                setQuery({
                    q: document.getElementById(`search`).value,
                    page: 1,
                });
            }}
        >
            <TextField
                className={`flex-auto uppercase`}
                defaultValue={query.q}
                fullWidth
                id="search"
                label="Search"
                margin="none"
                size="medium"
                variant="filled"
            />
            <Button
                className={`flex-none`}
                color="primary"
                endIcon={<SearchIcon />}
                style={{ marginLeft: theme.spacing(2) }}
                type="submit"
                variant="contained"
            >
                Search
            </Button>
        </form>
    );
};
