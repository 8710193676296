import * as React from "react";
import {
    Collapse,
    Grid,
    ListItem,
    ListItemText,
    Paper,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useIsMounted } from "../../common/hooks";
import { CardFilterForm } from "./CardFilterForm";

export const CardFilterWidget = ({ gridContainerClasses }) => {
    const isMounted = useIsMounted();
    const [filtersOpen, setFiltersOpen] = React.useState(false);
    const toggleFiltersOpen = () => {
        setFiltersOpen(!filtersOpen);
    };

    return isMounted ? (
        <Grid className={`mb-4 ${gridContainerClasses}`} container>
            <Grid item lg={4} md={6} xs={12}>
                <Paper>
                    <ListItem button onClick={toggleFiltersOpen}>
                        <ListItemText primary={`Filters`} />
                        {filtersOpen ? <ExpandMore /> : <ExpandLess />}
                    </ListItem>
                    <Collapse
                        className="p-4"
                        in={filtersOpen}
                        timeout="auto"
                        unmountOnExit
                    >
                        <CardFilterForm />
                    </Collapse>
                </Paper>
            </Grid>
        </Grid>
    ) : null;
};
