import * as React from "react";
import { useQueryParams, StringParam, NumberParam } from "use-query-params";
import { Link } from "gatsby";
import { Paper, Typography } from "@material-ui/core";
import { useIsMounted } from "../common/hooks";
import { Layout } from "../common/Layout";
import { SEO } from "../components/seo";
import { SearchForm } from "../features/search/SearchForm";
import { SearchResults } from "../features/search/SearchResults";
import { CardFilterWidget } from "../features/cards/CardFilterWidget";

/*
We're managing search filter state in the URL to provide browser history and shareable URLs
 */
const SearchScreen = () => {
    const isMounted = useIsMounted();
    const [query] = useQueryParams({
        q: StringParam,
        g: StringParam,
        se: NumberParam,
        s: NumberParam,
        page: NumberParam,
    });
    const validParams = query.q || query.g || query.se || query.s;
    const cardListOptions = {
        showManageListsButton: false,
    };

    return (
        <Layout>
            <SEO title="Card Search" />
            <SearchForm />
            {isMounted && validParams ? (
                <CardFilterWidget />
            ) : (
                <Paper className="mt-8 p-4">
                    <Typography className="pb-4">
                        Search is great if you know something about a card, but
                        aren't sure where to find it.
                    </Typography>
                    <Typography>
                        If you're looking for cards in a specific set, then
                        browsing{` `}
                        <Link className="font-bold" to="/games/">
                            the CCG Database
                        </Link>{` `}
                        would be a better place to start.
                    </Typography>
                </Paper>
            )}
            {validParams ? (
                <SearchResults cardListOptions={cardListOptions} />
            ) : null}
        </Layout>
    );
};

export default SearchScreen;
